<template>
  <div
    id="adLayer"
    dir="ltr"
    class="ad__layer absolute top-0 start-0 w-full h-full"
    @click.stop="toggleAdPlayback"
    @pointermove="onPointermove"
  >
    <Transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="showHoveredContent"
        @click.stop="toggleAdPlayback"
        class="absolute start-0 top-0 w-full h-full flex justify-center items-center"
      >
        <div
          class="z-0 absolute top-0 start-0 w-1/2 h-full gradient horizontal"
        ></div>
        <div
          class="z-0 absolute bottom-0 start-0 w-full h-1/2 gradient vertical"
        ></div>
        <template v-if="isMdAndUp">
          <ButtonsCta
            class="variant-glass"
            @click.stop="toggleAdPlayback"
          >
            <Icon
              v-if="store.playbackState == 'adplay'"
              name="IconPause"
              size="24"
            ></Icon>
            <Icon
              v-else
              name="IconPlay"
              size="24"
            ></Icon>
          </ButtonsCta>
        </template>
        <template v-else>
          <div
            class="p-4 flex items-center gap-x-7 rounded-full outline-none backdrop-blur-sm bg-white/[0.15]"
          >
            <button
              class="rounded-full bg-brand-red w-[52px] h-[52px]"
              custom-class
              @click.stop="toggleAdPlayback"
            >
              <Icon
                v-if="store.playbackState == 'adplay'"
                name="IconPause"
                size="24"
              ></Icon>
              <Icon
                v-else
                name="IconPlay"
                size="24"
              ></Icon>
            </button>
          </div>
        </template>
      </div>
    </Transition>
    <div class="absolute top-[5%] end-0 flex flex-col gap-y-4 py-4 px-6">
      <ButtonsCta
        @click.stop="onSkipAd"
        class="variant-secondary"
        v-show="isShowSkipAd"
      >
        {{ $t("skipAd") }}
      </ButtonsCta>
    </div>
    <div
      class="layer__controls absolute bottom-0 start-0 flex flex-col gap-y-4 w-full py-4 px-6"
      @click.stop=""
    >
      <div
        class="controls__adactions relative flex justify-end items-center gap-x-4"
      >
        <ButtonsCta
          id="learnMore"
          class="variant-secondary"
          @click.stop.prevent="onClickAd"
        >
          {{ $t("Learn more") }}
        </ButtonsCta>
      </div>
      <div class="controls__adinfo relative flex items-center">
        <span
          class="text-label-big text-white opacity-[0.87] ltr:tracking-[0.7px] rtl:tracking-normal"
        >
          {{ $t("adOf", { c: adCounter, t: breakAdIds.length }) }}
        </span>
      </div>
      <div
        ref="overbar"
        class="group relative h-1.5 bg-white/40 mb-4 rounded-xl flex items-center"
      >
        <div
          id="played"
          ref="played"
          class="z-10 bg-yellow-500 rounded-s-xl h-full ease-linear"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useResizeObserver } from "@vueuse/core";
import { onKeyStroke } from "@vueuse/core";
import { useMagicKeys } from "@vueuse/core";

const props = defineProps({
  playerId: {
    type: String,
    required: true,
  },
  player: Object,
  currentAd: Object,
});

const { isMdAndUp } = useBreakpoints();

const overbar = ref(null);
const played = ref();
const overbarWidth = ref();
const adCounter = ref(1);
const hideTimeout = ref();
const showHoveredContent = ref(false);

const store = useAllPlayers().getStore(props.playerId);

const adManager = computed(() => {
  return store.player.adManager;
});

const breakAdIds = computed(() => {
  return store.adBreak.breakAdIds;
});

onMounted(() => {
  setPosition();
});

const isShowSkipAd = computed(() => {
  if (
    adManager.value?.adSkipOffset > 0 &&
    store.adCurrentTime >= adManager.value?.adSkipOffset
  ) {
    return true;
  } else {
    return false;
  }
});

const currentAdConfig = computed(() => {
  const ad = adManager.value.currentAd_;
  return {
    ...ad,
    clickThroughUrl: store.currentAd.clickThroughUrl,
  };
});

const toggleAdPlayback = () => {
  hideUi();
  if (store.playbackState == "adplay") {
    return adManager.value.pause();
  }
  adManager.value.play();
};

const onClickAd = () => {
  adManager.value.pause();
  navigateTo(currentAdConfig.value.clickThroughUrl, {
    external: true,
    open: { target: "_blank" },
  });
};

const onSkipAd = () => {
  adManager.value.skipAd();
};

const setPosition = () => {
  const newPosition =
    (store.adCurrentTime / adManager.value.currentAd_.duration) * 100;
  played.value.style.width = `${newPosition}%`;
};

watch(() => store.adCurrentTime, setPosition);

// handle the width of the overbar
useResizeObserver(overbar, (entries) => {
  const entry = entries[0];
  const { width } = entry.contentRect;
  overbarWidth.value = width;
});

const onPointermove = ({ pointerType }) => {
  if (pointerType == "touch") return;
  showHoveredContent.value = true;
  hideUi();
};

function hideUi() {
  clearTimeout(hideTimeout.value); // Clear any existing timeout.
  hideTimeout.value = setTimeout(() => {
    hideTimeout.value && clearTimeout(hideTimeout.value);
    showHoveredContent.value = false;
  }, 3000);
}

onKeyStroke([" ", "Spacebar", "Space Bar"], (e) => {
  showHoveredContent.value = true;
  toggleAdPlayback();
  hideUi();
});

const skipAdKeys = useMagicKeys()["s+k+i+p"];
watch(skipAdKeys, (s) => {
  if (s) {
    console.info("🧙🏼‍♀️ skipping ad!");
    onSkipAd();
  }
});

onBeforeUnmount(() => {
  hideTimeout.value && clearTimeout(hideTimeout.value);
});
</script>

<script>
export default { name: "AdUI" };
</script>

<style lang="scss" scoped></style>
